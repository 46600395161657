<template>
  <div class="main">
    <div class="topBar">
      <div class="back" @click="back">
        <van-image
          width="9px"
          @click="back"
          height="15px"
          :src="require('@/assets/cls/arrow-l.png')"
        />
      </div>
      <div>{{ $t("answerChallenge.miningNum") }}</div>
      <div>
        <p>100</p>
        <p>c</p>
      </div>
    </div>
    <div class="topicw">
      <van-image
        :src="require('../assets/xxImage/index/datizhuanjing.png')"
        width="134px"
        height="36px"
      ></van-image>
      <div class="topic">
        <div class="topic_item">
          <div class="title">
            <span>{{ num + 1 }}</span
            >/5
          </div>
          <div class="topic_text">
            {{ lang == "zh" ? topic.content : topic.content_en }}
          </div>
          <div class="option">
            <!-- <div
              v-for="item in answerOption"
              :key="item.value"
              :class="[
                'option_item',
               item.sho == 2?(item.result? 'active': 'error'):( (active == item.opt) | (shows == item.opt)? item.result? 'active': 'error': '') 
               ,]"
              @click="toOption(item.opt, item)"
            > -->
            <div
              v-for="item in answerOption"
              :key="item.value"
              :class="[
                'option_item',
                item.sho == 2
                  ? lisct.indexOf(item.opt) != -1
                    ? item.result
                      ? 'active'
                      : 'error'
                    : ''
                  : (active == item.opt) | (shows == item.opt)
                  ? item.result
                    ? 'active'
                    : 'error'
                  : '',
              ]"
              @click="toOption(item.opt, item)"
            >
              <svg-icon
                v-if="(active == item.opt) | (shows == item.opt)"
                :icon-class="item.result ? 'yes' : 'cuo'"
                style="
                  font-size: 15px;
                  position: absolute;
                  left: 10px;
                  color: white;
                "
              ></svg-icon>
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="index">
          <div class="circle_conainer">
            <svg class="circle" viewBox="0 0 1040 1040">
              <path
                ref="cir"
                class="van-circle__layer"
                d="M 520 520 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000"
                style="fill: none; stroke-width: 30px"
              ></path>
              <path
                class="van-circle__hover"
                d="M 520 520 m 0 -500 a 500 500 0 1 1 0 1000 a 500 500 0 1 1 0 -1000"
                :style="ps"
              ></path>
            </svg>
            <div class="van-circle__text">{{ countdownValue }}</div>
          </div>
          <p class="tiles" v-if="bottomTitle == 1">
            {{ $t("answerChallenge.AddDraft") }}
          </p>
          <p class="tiles" v-if="bottomTitle == 2">
            <span style="color: #ee2d3d">{{
              $t("answerChallenge.Addmining")
            }}</span
            >，{{ $t("answerChallenge.card") }}
          </p>
          <p class="tiles" v-if="bottomTitle == 3">
            <span style="color: #12d637">{{
              $t("answerChallenge.Successfully")
            }}</span
            >，{{ $t("answerChallenge.redeem") }}
          </p>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showRight"
      position="right"
      :style="{ width: '100%', height: '100%' }"
    >
      <div class="topBar">
        <div class="back" @click="back">
          <van-image
            width="9px"
            height="15px"
            :src="require('@/assets/cls/arrow-l.png')"
          />
        </div>
        <div>{{ $t("answerChallenge.miningNum") }}</div>
        <div>
          <p>100</p>
          <p>c</p>
        </div>
      </div>
      <div class="complete">
        <van-image
          v-if="toError"
          :src="require('../assets/xxImage/index/nocg.png')"
          width="80px"
          height="80px"
        ></van-image>
        <van-image
          v-if="!toError"
          :src="require('../assets/xxImage/index/yescg.png')"
          width="80px"
          height="80px"
        ></van-image>
        <p class="p1" v-if="!toError">{{ $t("answerChallenge.income") }}</p>
        <p class="p1" v-if="toError" style="color: #e5254e">
          {{ $t("answerChallenge.cancellation") }}<br />{{
            $t("answerChallenge.determine")
          }}
        </p>
        <div class="getbi" v-if="!toError">
          <van-image
            width="22px"
            height="28px"
            :src="require('@/assets/xxImage/index/jiangli.png')"
          ></van-image>
          <p class="p3">{{ $t("answerChallenge.available") }} X1</p>
        </div>
      </div>
    </van-popup>
    <div class="showModel" v-if="Correct_answer"></div>
    <div class="Correct_answer" v-if="Correct_answer">
      <van-image
        :src="require('../assets/xxImage/index/nocg.png')"
        width="80px"
        height="80px"
      ></van-image>
      <p
        style="
          color: #f56c6c;
          font-size: 15px;
          font-weight: bold;
          margin-top: 10px;
        "
      >
        {{ $t("answerPage.card") }}
      </p>
      <p class="zqDa">
        {{ $t("answerPage.Successfully") }}:
        <span style="color: #8563f6">{{ zqServe }}</span>
      </p>
      <div class="btn">
        <van-button @click="CnexnQuestion">
          <span class="">{{ $t("answerPage.cxzd") }}</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Correct_answer: false, //错误弹框
      showRight: false,
      active: 10,
      ps: {
        "stroke-width": "40px",
        "stroke-dasharray": "0, 0",
      },
      countdowntext: "倒计时",
      countDownSeconds: 10, //900秒相当于 15分钟
      t: null,
      activeprogress: 0, //当前进度
      stepSeconds: 0, //圆环每秒走的进度
      correct: 2, //正确答案
      bottomTitle: 1,
      shows: 0,
      toError: false,
      attributeList: [], //题目列表
      topic: "", //题目
      answerOption: [], //答案选项列表
      select: "", //选中选项
      zqServe: "", //正确答案
      manyNum: 0, //第几题
      reward: "", //答题奖励
      num: 0,
      lisct: [],
    };
  },
  computed: {
    countdownValue() {
      return this.countdownTime(this.countDownSeconds);
    },
    lang() {
      return this.$storage.get("lang");
    },
  },
  created() {
    this.getChallenge();
    this._i18n.locale = this.$storage.get("lang"); //设置初始化语言
  },
  mounted() {
    this.countdown();
  },
  methods: {
    // 倒计时结束
    countdown() {
      let cir = this.$refs.cir;
      let circleTotalLength = parseInt(cir.getTotalLength()); //圆圈的总长度
      this.stepSeconds = Number(
        (circleTotalLength / this.countDownSeconds).toFixed(2)
      ); //每秒走的进度
      this.timingstart(this.stepSeconds, circleTotalLength, "no");
    },
    handleFun(list) {
      this.answerOption = [];
      this.topic = list;
      this.zqServe = this.topic.answer;
      this.correct = this.topic.answer;
      let mapList = null;
      if (this.lang == "zh") {
        mapList = JSON.parse(this.topic.option);
      } else {
        mapList = JSON.parse(this.topic.option_en);
      }
      if (list.type == 1) {
        //处理选项列表数据
        mapList.forEach((item, index) => {
          //用for in取值
          for (let i in item) {
            this.answerOption.push({
              opt: i,
              value: item[i],
              type: list.type,
              display: false,
              result: null,
            });
          }
        });
      } else {
        //处理选项列表数据
        mapList.forEach((item, index) => {
          //用for in取值
          for (let i in item) {
            this.answerOption.push({
              opt: i,
              value: item[i],
              type: list.type,
              display: false,
              result: null,
              sho: 2,
            });
          }
        });
      }
    },
    //获取答题挑战题目
    getChallenge() {
      this.$axios.get(this.$api.challenge).then((res) => {
        this.attributeList = res.data;
        this.handleFun(this.attributeList[this.manyNum]);
      });
    },
    back() {
      this.$router.push('/answer')
    },
    toOption(num, dom) {
      //已作答
      if (this.bottomTitle == 3 || this.bottomTitle == 2) {
        return;
      }
      clearInterval(this.t);
      this.active = num;
      let oneIf = false;
      this.answerOption.forEach((item, index) => {
        if (item.type == 1) {
          if (item.value == dom.value) {
            if (this.correct == num) {
              item.result = true;
              this.bottomTitle = 3; //3表示回答正确

              //控制圆圈倒计时重置
              clearTimeout(this.t);
              this.countDownSeconds = 5;
              let cir = this.$refs.cir;
              let circleTotalLength = parseInt(cir.getTotalLength()); //圆圈的总长度
              this.activeprogress = 0;
              this.stepSeconds = Number(
                (circleTotalLength / this.countDownSeconds).toFixed(2)
              ); //每秒走的进度
              this.timingstart(this.stepSeconds, circleTotalLength, "en");
            } else {
              this.Correct_answer = true;
              return;

              item.result = false;
              this.bottomTitle = 2; //2表示回答错误
              this.toError = true;

              //控制圆圈倒计时重置
              clearTimeout(this.t);
              this.countDownSeconds = 5;
              let cir = this.$refs.cir;
              let circleTotalLength = parseInt(cir.getTotalLength()); //圆圈的总长度
              this.activeprogress = 0;
              this.stepSeconds = Number(
                (circleTotalLength / this.countDownSeconds).toFixed(2)
              ); //每秒走的进度
              this.timingstart(this.stepSeconds, circleTotalLength, "no");

              //判断正确的方法
              for (let i = 0; i < this.answerOption.length; i++) {
                if (this.answerOption[i].opt == this.correct) {
                  this.shows = this.answerOption[i].opt;
                  this.answerOption[i].result = true;
                }
              }
            }
          }
        } else {
          //多选题对错判断逻辑
          if (this.lisct.indexOf(num) != -1 || this.bottomTitle == 3) {return;}
          if (item.value == dom.value) {
            if (this.correct.indexOf(num) != -1) {
              item.result = true;
              this.lisct.push(num);
              let nums = 0;
              this.lisct.forEach((item) => {
                if (this.correct.indexOf(item) != -1) {
                  nums++;
                }
              });
              if (nums == this.correct.split(",").length) {
                this.bottomTitle = 3; //3表示回答正确
                clearTimeout(this.t);
                this.countDownSeconds = 5;
                let cir = this.$refs.cir;
                let circleTotalLength = parseInt(cir.getTotalLength()); //圆圈的总长度
                this.activeprogress = 0;
                this.stepSeconds = Number(
                  (circleTotalLength / this.countDownSeconds).toFixed(2)
                ); //每秒走的进度
                this.timingstart(this.stepSeconds, circleTotalLength, "en");
              }
            } else {
              this.Correct_answer = true;
              return;
            }
          }
        }
      });
    },
    //重新答题
    CnexnQuestion() {
      this.lisct = [];
      this.active = null;
      clearTimeout(this.t);
      this.countDownSeconds = 10;
      this.activeprogress = 0; //圆圈进度
      this.countdown();
      this.Correct_answer = false;
    },
    timingstart(step_, circleTotalLength, toError) {
      this.countDownSeconds--;
      this.activeprogress += step_;
      this.ps = {
        "stroke-width": "41px",
        "stroke-dasharray": `${this.activeprogress.toFixed(
          2
        )}px,${circleTotalLength}px`,
      };
      if (this.countDownSeconds <= 0) {
        this.lisct = []
        clearTimeout(this.t);
        if (toError === "en") {
          if (this.num == 4) {
            //答完5题跳转
            this.showRight = true;
            this.$axios
              .post(this.$api.answerChallenge, { num: this.num + 1 })
              .then((res) => {});
            return;
          }
          this.num += 1;
          this.handleFun(this.attributeList[this.num]);
          this.active = null;
          this.bottomTitle = 1;
          this.countDownSeconds = 10; //倒计时秒钟
          this.activeprogress = 0; //圆圈进度
          this.countdown();
        }
        if (toError === "no") {
          this.showRight = true;
          this.toError = true;
          //答错题跳转
          // this.showRight = true;
          this.$axios
            .post(this.$api.answerChallenge, { num: this.num + 1 })
            .then((res) => {});
        }
      } else {
        this.t = setTimeout(() => {
          this.timingstart(this.stepSeconds, circleTotalLength, toError);
        }, 1000);
      }
    },
    countdownTime(Seconds = 60) {
      var d = parseInt(Seconds / 60 / 60 / 24); //获取天数
      var h = parseInt((Seconds / 60 / 60) % 24); //获取小时数
      var m = parseInt((Seconds / 60) % 60); //获取分钟数
      var s = parseInt(Seconds % 60); //获取秒数
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      return `${s}`;
    },
    //跳转答题页
    toAnswer() {
      this.$router.push("/answer");
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100vh;
  background: url("../assets/xxImage/index/datitiaozhanbG.png") no-repeat;
  background-size: 100% 100%;
}
.topBar {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 0 10px;
  div:nth-of-type(1) {
    width: 85px;
    font-size: 15px;
    font-weight: bold;
  }
  div:nth-of-type(2) {
    font-size: 18px;
  }
  div:nth-of-type(3) {
    width: 81px;
    height: 31px;
    border: 1px solid #000;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p:nth-of-type(1) {
      width: 50%;
      text-align: right;
    }
    p:nth-of-type(2) {
      width: 31px;
      height: 31px;
      border: 1px solid #000;
      border-right: 0px;
      background: #8f93fb;
      border-radius: 50%;
      text-align: center;
      line-height: 28px;
      color: white;
    }
  }
}
.title {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topicw {
  width: 100%;
  // height: 610px;
  background: url("../assets/xxImage/index/datibGkb.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topic {
  width: 346px;
  // height: 330px;
  margin: 0 auto;
  margin-top: 230px;
  padding-bottom: 31px;
  .topic_item {
    width: 346px;
    // height: 250px;
    padding: 20px 10px;
    padding-bottom: 20px;
    .title {
      width: 53px;
      height: 17px;
      background: white;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      margin: 0 auto;
      span {
        color: #ff9600;
      }
    }
    .topic_text {
      width: 100%;
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 16px;
    }
    .option {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .option_item {
        position: relative;
        width: 206px;
        // height: 34px;
        background: white;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #666666;
        margin-top: 16px;
        padding: 10px 10px;
      }
    }
  }
}
.active {
  background: #4586f5 !important;
  color: white !important;
}
.index {
  .tiles {
    margin-top: 10px;
    text-align: center;
    color: #999999;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
}
.circle {
  color: #999999;
}
.circle_conainer {
  position: relative;
  width: 40px;
  margin: 0 auto;
}
.van-circle__layer {
  stroke: #999999;
}
.van-circle__hover {
  fill: none;
  stroke: #d6ecbf;
  stroke-linecap: round;
}
.van-circle__text {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.error {
  background: #fd8877 !important;
  color: white !important;
}
.complete {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .p1 {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #29db53;
    margin-top: 10px;
    text-align: center;
  }
  .p2 {
    margin-top: 10px;
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
  }
  .getbi {
    width: 125px;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f4f4f4;
    border-radius: 5px;
    margin-top: 10px;
    .p3 {
      color: #8563f6;
      font-size: 10px;
      margin-top: 4px;
    }
  }
}
.Correct_answer {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 181px;
  height: 211px;
  border: 1px solid #000;
  border-radius: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  z-index: 11;
  .zqDa {
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    margin-top: 12px;
  }
}
.showModel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.btn {
  button {
    margin-top: 26px;
    width: 150px;
    height: 35px;
    border: 1px solid #000;
    border-radius: 5px;
    background: #8f93fb;
    margin-left: 50%;
    transform: translateX(-50%);
    color: white;
  }
}
</style>